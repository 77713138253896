export const STORE_INITIALIZE = 'STORE_INITIALIZE';

export const SUPPORT_EMAIL = 'support@closeby.zendesk.com';

export const GET_LOCATIONS = 'GET_LOCATIONS';
export const LOCATIONS_UPDATE_LIST = 'LOCATIONS_UPDATE_LIST';

export const LOCATION_SHOW_FORM = 'LOCATION_SHOW_FORM';
export const LOCATION_HIDE_FORM = 'LOCATION_HIDE_FORM';
export const LOCATION_UPDATE_FORM_VALUES = 'LOCATION_UPDATE_FORM_VALUES';
export const LOCATION_SAVE_FORM_VALUES = 'LOCATION_SAVE_FORM_VALUES';

export const GET_LOCATIONS_NEARBY_REQUESTED = 'GET_LOCATIONS_NEARBY_REQUESTED';
export const GET_LOCATIONS_NEARBY_FULFILLED = 'GET_LOCATIONS_NEARBY_FULFILLED';
export const GET_LOCATIONS_NEARBY_REJECTED = 'GET_LOCATIONS_NEARBY_REJECTED';
export const LOCATOR_UPDATE = 'LOCATOR_UPDATE';

export const MAPS_EDITOR_UPDATE = 'MAPS_EDITOR_UPDATE';
export const MAPS_EDITOR_SAVE = 'MAPS_EDITOR_SAVE';
export const MAPS_EDITOR_MODAL_ID = '#maps-editor'

export const STATS_UPDATE_VALUES = 'STATS_UPDATE_VALUES';
export const STATS_UPDATE_RECEIVE = 'STATS_UPDATE_RECEIVE';
export const GET_STATS_SUGGESTIONS = 'GET_STATS_SUGGESTIONS';
export const GET_STATS_VIEWS = 'GET_STATS_VIEWS';
export const GET_STATS_DEVICES = 'GET_STATS_DEVICES';

export const EMBED_UPDATE = 'EMBED_UPDATE';
export const TWITTER_PICKER_COLORS = ['#ea704b', '#715f30', '#88852b', '#5eaf44', '#30bd68', '#406bcc', '#0a7cbd', '#8195a7', '#514fb1', '#bf1b46', '#d03434', '#cc7021', '#7d35a5', '#724f98', '#4a4a4a'];
export const COUNTRY_CODES = {
  'AF': 'Afghanistan',
  'AX': 'Aland Islands',
  'AL': 'Albania',
  'DZ': 'Algeria',
  'AS': 'American Samoa',
  'AD': 'Andorra',
  'AO': 'Angola',
  'AI': 'Anguilla',
  'AQ': 'Antarctica',
  'AG': 'Antigua And Barbuda',
  'AR': 'Argentina',
  'AM': 'Armenia',
  'AW': 'Aruba',
  'AU': 'Australia',
  'AT': 'Austria',
  'AZ': 'Azerbaijan',
  'BS': 'Bahamas',
  'BH': 'Bahrain',
  'BD': 'Bangladesh',
  'BB': 'Barbados',
  'BY': 'Belarus',
  'BE': 'Belgium',
  'BZ': 'Belize',
  'BJ': 'Benin',
  'BM': 'Bermuda',
  'BT': 'Bhutan',
  'BO': 'Bolivia',
  'BA': 'Bosnia And Herzegovina',
  'BW': 'Botswana',
  'BV': 'Bouvet Island',
  'BR': 'Brazil',
  'IO': 'British Indian Ocean Territory',
  'BN': 'Brunei Darussalam',
  'BG': 'Bulgaria',
  'BF': 'Burkina Faso',
  'BI': 'Burundi',
  'KH': 'Cambodia',
  'CM': 'Cameroon',
  'CA': 'Canada',
  'CV': 'Cape Verde',
  'KY': 'Cayman Islands',
  'CF': 'Central African Republic',
  'TD': 'Chad',
  'CL': 'Chile',
  'CN': 'China',
  'CX': 'Christmas Island',
  'CC': 'Cocos (Keeling) Islands',
  'CO': 'Colombia',
  'KM': 'Comoros',
  'CG': 'Congo',
  'CD': 'Congo, Democratic Republic',
  'CK': 'Cook Islands',
  'CR': 'Costa Rica',
  'CI': 'Cote D\'Ivoire',
  'HR': 'Croatia',
  'CU': 'Cuba',
  'CY': 'Cyprus',
  'CZ': 'Czech Republic',
  'DK': 'Denmark',
  'DJ': 'Djibouti',
  'DM': 'Dominica',
  'DO': 'Dominican Republic',
  'EC': 'Ecuador',
  'EG': 'Egypt',
  'SV': 'El Salvador',
  'GQ': 'Equatorial Guinea',
  'ER': 'Eritrea',
  'EE': 'Estonia',
  'ET': 'Ethiopia',
  'FK': 'Falkland Islands (Malvinas)',
  'FO': 'Faroe Islands',
  'FJ': 'Fiji',
  'FI': 'Finland',
  'FR': 'France',
  'GF': 'French Guiana',
  'PF': 'French Polynesia',
  'TF': 'French Southern Territories',
  'GA': 'Gabon',
  'GM': 'Gambia',
  'GE': 'Georgia',
  'DE': 'Germany',
  'GH': 'Ghana',
  'GI': 'Gibraltar',
  'GR': 'Greece',
  'GL': 'Greenland',
  'GD': 'Grenada',
  'GP': 'Guadeloupe',
  'GU': 'Guam',
  'GT': 'Guatemala',
  'GG': 'Guernsey',
  'GN': 'Guinea',
  'GW': 'Guinea-Bissau',
  'GY': 'Guyana',
  'HT': 'Haiti',
  'HM': 'Heard Island & Mcdonald Islands',
  'VA': 'Holy See (Vatican City State)',
  'HN': 'Honduras',
  'HK': 'Hong Kong',
  'HU': 'Hungary',
  'IS': 'Iceland',
  'IN': 'India',
  'ID': 'Indonesia',
  'IR': 'Iran, Islamic Republic Of',
  'IQ': 'Iraq',
  'IE': 'Ireland',
  'IM': 'Isle Of Man',
  'IL': 'Israel',
  'IT': 'Italy',
  'JM': 'Jamaica',
  'JP': 'Japan',
  'JE': 'Jersey',
  'JO': 'Jordan',
  'KZ': 'Kazakhstan',
  'KE': 'Kenya',
  'KI': 'Kiribati',
  'KR': 'Korea',
  'KW': 'Kuwait',
  'KG': 'Kyrgyzstan',
  'LA': 'Lao People\'s Democratic Republic',
  'LV': 'Latvia',
  'LB': 'Lebanon',
  'LS': 'Lesotho',
  'LR': 'Liberia',
  'LY': 'Libyan Arab Jamahiriya',
  'LI': 'Liechtenstein',
  'LT': 'Lithuania',
  'LU': 'Luxembourg',
  'MO': 'Macao',
  'MK': 'Macedonia',
  'MG': 'Madagascar',
  'MW': 'Malawi',
  'MY': 'Malaysia',
  'MV': 'Maldives',
  'ML': 'Mali',
  'MT': 'Malta',
  'MH': 'Marshall Islands',
  'MQ': 'Martinique',
  'MR': 'Mauritania',
  'MU': 'Mauritius',
  'YT': 'Mayotte',
  'MX': 'Mexico',
  'FM': 'Micronesia, Federated States Of',
  'MD': 'Moldova',
  'MC': 'Monaco',
  'MN': 'Mongolia',
  'ME': 'Montenegro',
  'MS': 'Montserrat',
  'MA': 'Morocco',
  'MZ': 'Mozambique',
  'MM': 'Myanmar',
  'NA': 'Namibia',
  'NR': 'Nauru',
  'NP': 'Nepal',
  'NL': 'Netherlands',
  'AN': 'Netherlands Antilles',
  'NC': 'New Caledonia',
  'NZ': 'New Zealand',
  'NI': 'Nicaragua',
  'NE': 'Niger',
  'NG': 'Nigeria',
  'NU': 'Niue',
  'NF': 'Norfolk Island',
  'MP': 'Northern Mariana Islands',
  'NO': 'Norway',
  'OM': 'Oman',
  'PK': 'Pakistan',
  'PW': 'Palau',
  'PS': 'Palestinian Territory, Occupied',
  'PA': 'Panama',
  'PG': 'Papua New Guinea',
  'PY': 'Paraguay',
  'PE': 'Peru',
  'PH': 'Philippines',
  'PN': 'Pitcairn',
  'PL': 'Poland',
  'PT': 'Portugal',
  'PR': 'Puerto Rico',
  'QA': 'Qatar',
  'RE': 'Reunion',
  'RO': 'Romania',
  'RU': 'Russian Federation',
  'RW': 'Rwanda',
  'BL': 'Saint Barthelemy',
  'SH': 'Saint Helena',
  'KN': 'Saint Kitts And Nevis',
  'LC': 'Saint Lucia',
  'MF': 'Saint Martin',
  'PM': 'Saint Pierre And Miquelon',
  'VC': 'Saint Vincent And Grenadines',
  'WS': 'Samoa',
  'SM': 'San Marino',
  'ST': 'Sao Tome And Principe',
  'SA': 'Saudi Arabia',
  'SN': 'Senegal',
  'RS': 'Serbia',
  'SC': 'Seychelles',
  'SL': 'Sierra Leone',
  'SG': 'Singapore',
  'SK': 'Slovakia',
  'SI': 'Slovenia',
  'SB': 'Solomon Islands',
  'SO': 'Somalia',
  'ZA': 'South Africa',
  'GS': 'South Georgia And Sandwich Isl.',
  'ES': 'Spain',
  'LK': 'Sri Lanka',
  'SD': 'Sudan',
  'SR': 'Suriname',
  'SJ': 'Svalbard And Jan Mayen',
  'SZ': 'Swaziland',
  'SE': 'Sweden',
  'CH': 'Switzerland',
  'SY': 'Syrian Arab Republic',
  'TW': 'Taiwan',
  'TJ': 'Tajikistan',
  'TZ': 'Tanzania',
  'TH': 'Thailand',
  'TL': 'Timor-Leste',
  'TG': 'Togo',
  'TK': 'Tokelau',
  'TO': 'Tonga',
  'TT': 'Trinidad And Tobago',
  'TN': 'Tunisia',
  'TR': 'Turkey',
  'TM': 'Turkmenistan',
  'TC': 'Turks And Caicos Islands',
  'TV': 'Tuvalu',
  'UG': 'Uganda',
  'UA': 'Ukraine',
  'AE': 'United Arab Emirates',
  'GB': 'United Kingdom',
  'US': 'United States',
  'UM': 'United States Outlying Islands',
  'UY': 'Uruguay',
  'UZ': 'Uzbekistan',
  'VU': 'Vanuatu',
  'VE': 'Venezuela',
  'VN': 'Viet Nam',
  'VG': 'Virgin Islands, British',
  'VI': 'Virgin Islands, U.S.',
  'WF': 'Wallis And Futuna',
  'EH': 'Western Sahara',
  'YE': 'Yemen',
  'ZM': 'Zambia',
  'ZW': 'Zimbabwe'
};

export const DEFAULT_POSITION_TEXTS = {
  specific_location: {
    notice: "Users automatically see one location selected when your widget loads.",
  },
  show_all: {
    details: "Shows all your locations in your map by default.",
    copy: [
      "This will show all your locations in the world when the map loads and requires search/zooming to specific locations. Locations are sorted alphabetically.",
      "This may have performance challenges when displaying thousands of locations.",
      "You can also add a loading map position for your map below.",
    ],
  },
  geolocate: {
    details: "Request visitor's location to center your map.",
    copy: [
      "This will ask your visitors to share their location, which will appear on your map. The user location color is always editable in your Advanced Styles.",
      "If they choose not to share their location, your map will use the default geographic position for your map defined below.",
    ],
  },
  viewport: {
    details: "Manually set a region to show in your map by default.",
    copy: ["This will set a default geographic position for your map."],
  },
};

export const DEFAULT_POSITION_OPTIONS = [
  { value: 'show_all', label: 'Show all locations' },
  { value: 'geolocate', label: "Geolocate based on user's location" },
  { value: 'viewport', label: 'Set a default map location' },
  { value: 'specific_location', label: 'One specific location' },
];

export const AUTOCOMPLETE_OPTIONS = [
  { value: 'suggestion_include_locality', label: 'Country, Region & City' },
  { value: 'suggestion_include_zip', label: 'Country, Region, City & Postcode' },
  { value: 'suggestion_include_address', label: 'Country, Region, City, Postcode & Address' }
];

export const TABS = [
  { key: 'general', label: 'General' },
  { key: 'geography', label: 'Map Position & Geography' },
  { key: 'image', label: 'Location Images' },
  { key: 'mobile', label: 'Mobile' },
  { key: 'pin', label: 'Map Pins' },
  { key: 'theme', label: 'Map Theme' },
  { key: 'text', label: 'Language & Text' },
  { key: 'advanced', label: 'Advanced Styles' },
];

export const ZENDESK_LINK = 'https://closeby.zendesk.com/hc/en-us/articles/16083895811090';

